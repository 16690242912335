<template>
  <div>
  <div class="row gap-5">
    <div class="block   w-full text-right mr-2">
      <router-link to="/vdi/list" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به لیست دسکتاپ ها</router-link>
      </div>
    <div class="bg-h-primary-light w-full  lg:w-1/4 p-5 rounded-lg border border-dashed border-white border-opacity-25 shadow-lg">
      <vdi-info-card v-if="isCloudInfoLoaded" @editeCloud="editCloud" :data="cloudInfo" :key="cloudInfo.id"></vdi-info-card>

    </div>

    <div class="w-full lg:w-2/3">
      <div class="float-right hidden lg:block">
        <!-- <router-link to="/dashboard" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
      </div>
      <b-tabs content-class="mt-3" refs="tabs" >

        <b-tab title="پلن" :active="$route.hash === '#plan'" href="plan" @click="tabHash('plan')">
          <vdi-plan-list v-if="isCloudInfoLoaded" @editeCloud="editCloud" :data="cloudInfo" :key="cloudInfo.id"></vdi-plan-list>
        </b-tab>

        <b-tab title="رویدادها" :active="$route.hash === '#events'" href="events" @click="tabHash('events')">
          <vira-tasks v-if="isCloudInfoLoaded" @editeCloud="editCloud" :data="cloudInfo" :key="cloudInfo.id"></vira-tasks>
        </b-tab>
      </b-tabs>
      </div>

  </div>

  </div>

</template>

<script>
import VdiInfoCard     from './VdiInfoCard.vue'
import ViraTasks     from './ViraTasks.vue'
import VdiPlanList from './VdiPlanList.vue'

export default {
  components : {
    VdiInfoCard,
    VdiPlanList,
    ViraTasks
  },
  data () {
    return {
      cloudId : '',
      cloudInfo : {},
      isCloudInfoLoaded: false,
      VDILink:'',
      myloc: ''
    }
  },
  watch : {
    $route () {
      this.cloudId = this.$route.params.id
      this.getInfo()
    }
  },
  computed: {

  },
  methods : {
    tabHash (val) {
      location.hash = val
    },
    editCloud (val) {
      const data = {
        vm_title: val.title
      }
      this.$http.put(`/cloud/desktop/${this.cloudId}`, data).then((result) => {
        this.$vs.notify({text:result.data.message})
        this.getInfo()
      }).catch((err) => {
        this.$vs.notify({text: err.response.data.message, color: 'danger'})
      })
    },
    getInfo () {
      this.$http.get(`/cloud/desktop/${this.cloudId}`).then((result) => {
        this.cloudInfo = result.data.params
        this.isCloudInfoLoaded = true
      }).catch(() => {

      })
    }
  },
  created () {
    this.cloudId = this.$route.params.id
    this.getInfo()
  }
}
</script>
