<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">
      <div class="flex w-full justify-end mb-2">

        <span @click="getTasks (), rotation = !rotation" class="btn btn-light items-center flex w-32 justify-center gap-2">
          <feather-icon id="traffic"  icon="RefreshCwIcon" :class="{ reload: rotation }"
          class="w-6 rotate" />
          تازه سازی

        </span>
      </div>
      <table class="w-full text-sm text-left  text-grey rounded-t">
          <thead class="text-xs text-grey ">
            <tr class="border  border-white border-opacity-25">
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">شناسه</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">نوع رویداد</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">وضعیت</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">جزئیات</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">زمان</th>
            </tr>
          </thead>
          <tbody class="">
            <tr class="border border-white border-opacity-25" v-for="item in tasks" :key="item.id">
              <th scope="row" class="px-6 py-4 font-medium text-grey whitespace-nowrap">{{ item.id }}</th>
              <td class="border border-white border-opacity-25 px-6 py-4">{{ item.method_name }}</td>
              <td class="border border-white border-opacity-25 px-6 py-4">{{ item.status }}</td>
              <td class="border border-white border-opacity-25 px-6 py-4">
                <ul>
                  <li v-if="item.details.prev_cpu !== item.details.new_cpu">CPU: از {{ item.details.prev_cpu }} به {{ item.details.new_cpu }}Core</li>
                  <li v-if="item.details.prev_ram !== item.details.new_ram">RAM: از {{ item.details.prev_ram }} به {{ item.details.new_ram }}GB</li>
                  <li v-if="item.details.prev_hdd !== item.details.new_hdd">Hard: از {{ item.details.prev_hdd }} به {{ item.details.new_hdd }}GB</li>
                </ul>
              </td>
              <td class="border border-white border-opacity-25 px-6 py-4">{{ item.created_at }}</td>
            </tr>
          </tbody>
        </table>
      <b-pagination class="mt-4 right-0" v-model="currentPage" :total-rows="tasksParams.total_items" :per-page="tasksParams.per_page"></b-pagination>
  </div>

    <!-- end of tasks -->

  </div>
</template>

<script>
// import RoundSlider from 'vue-round-slider'

export default {
  components: {
    // RoundSlider
  },
  props: ['data'],
  data () {
    return {
      vm_number: '',
      tasksParams: {},
      tasks: [],
      currentPage: 1,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'method_name', label: 'Method Name' },
        { key: 'status', label: 'Status' },
        { key: 'details', label: 'Details' },
        { key: 'created_at', label: 'Created At' }
      ],
      rotation: false,
      rotate: false
    }
  },
  methods: {
    getTasks () {
      if (this.vm_number !== '' && typeof this.vm_number !== 'undefined') {
        this.$http.get(`cloud/desktop/${this.vm_number}/tasks?page=${this.currentPage}`)
          .then(response => {
            this.tasksParams = response.data.params
            this.tasks = response.data.params.data
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  },
  mounted () {
    this.vm_number = this.data.vm_sequence_id
    this.getTasks()
  }
}
</script>

<style>
tbody tr:nth-child(odd){
  background-color: #090C2B;
  color: #fff;
}
.nav-tabs {
  border: 0;
}
.nav-tabs .nav-link {
    color: #1791da !important;
    margin-right: 10px;
    border: 1px dashed #ffffff78 !important;
    border-radius: 8px !important;
}
.nav-tabs .nav-link:hover {
  color: white !important;
    border-radius: 8px !important;
    border: 1px dashed #1791da !important;
}
[dir] .nav-tabs .nav-link.active, [dir] .nav-tabs .nav-item.show .nav-link {
    background-color: unset !important;

    color: white !important;
    border-radius: 8px !important;
    border: 1px dashed #1791da !important;
}
</style>
