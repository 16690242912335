<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">
      <div class="flex w-full justify-end mb-2">

        <span @click="getPlans (false), rotation = !rotation" class="btn btn-light items-center flex w-32 justify-center gap-2">
          <feather-icon id="traffic"  icon="RefreshCwIcon" :class="{ reload: rotation }"
          class="w-6 rotate" />
          تازه سازی

        </span>
      </div>

      <div class="mt-3 mb-3 bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border">
        <b-tabs content-class="mt-3" class="mb-3 " >

          <div class="lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">

            <div :key="plan.id" v-for="(plan) in plans">
              <b-form-radio button button-variant="outline-light"
                            v-model="SelectedPlanID" :key="plan.id"
                            @change="setPlanResource(SelectedPlanID)"
                            :value="plan.id"
                            class="border-opacity-25 w-full mb-2 lg:mb-0">
                <div class="md:flex md:items-center md:gap-2 px-10 py-2  rounded">
                  {{plan.plan_title}}
                </div>
              </b-form-radio>

            </div>

          </div>

          <div v-if="(SelectedPlanID !== '')" class="mt-3 lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">
            <div class="bg-grey-light text-h-primary p-2 rounded-lg rounded-t-sm text-center">
              <span class=" text-sm"> Ram: {{ ram }} | </span>
              <span class=" text-sm"> CPU: {{ cpu }} | </span>
              <span class=" text-sm"> Hard: {{ hard }}</span>
            </div>
          </div>

        </b-tabs>
      </div>

      <b-row v-if="(SelectedPlanID !== '')" class="block md:flex mt-16 items-center p-5 rounded border-white border-dashed border-opacity-25 border">

        <b-col lg="6" md="6" class="text-center md:text-right flex gap-1">

          <div class="bg-grey-light text-h-primary p-2 text-center">
            <span class=" text-sm"> هزینه ساعتی (روشن): {{ online_price }} تومان</span>
          </div>

          <div class="bg-grey-light text-h-primary p-2 text-center">
            <span class=" text-sm"> هزینه ساعتی (خاموش): {{ shutdown_price }} تومان</span>
          </div>

        </b-col>

        <b-col lg="6" md="6" class="text-center md:text-right flex justify-end gap-1">
          <b-button @click="changePlanPromptModal()" variant="primary" class="block text-base md:text-lg px-5 md:px-10 py-3">تغییر پلن</b-button>
        </b-col>
      </b-row>


  </div>

    <!-- end of tasks -->
    <div>
      <vs-popup :active.sync="changePlanPrompt" title="تایید تغیر پلن">
        <div class="con-exemple-prompt">
          <div>
            <p>آیا از تغیر پلن اطمینان دارید؟ </p>
          </div>
          <vs-button
            icon=""
            class="float-right rounded"
            color="success"
            @click="changePlan"
          >بله</vs-button>
        </div>
      </vs-popup>
    </div>

  </div>
</template>

<script>
// import RoundSlider from 'vue-round-slider'

export default {
  components: {
    // RoundSlider
  },
  props: ['data'],
  data () {
    return {
      vm_number: this.data.vm_sequence_id,
      planParams: {},
      plans: [],

      SelectedPlanID: '',
      SelectedPlan: {},
      ram:'',
      cpu:'',
      hard:'',
      online_price:0,
      shutdown_price:0,
      datacenters: [],
      locations: [],

      changePlanPrompt: false,
      rotation: false,
      rotate: false
    }
  },
  methods: {
    getPlans (createSection = true) {
      this.$http.get(`cloud/desktop/plans?location=${this.data.location.id}`)
        .then(response => {
          this.planParams = response.data.params
          this.plans = response.data.params.data
          if (!createSection) {
            this.SelectedPlanID = this.data.plan.id
          }
          this.setPlanResource(this.SelectedPlanID)
        })
        .catch(error => {
          console.error(error)
        })
    },
    setPlanResource (id) {
      if (id !== '') {
        this.SelectedPlan = this.plans.find((car) => car.id === parseInt(id))
        this.cpu = this.SelectedPlan.plan_cpu
        this.ram = this.SelectedPlan.plan_ram
        this.hard = this.SelectedPlan.plan_storage
        this.online_price = this.SelectedPlan.plan_online_price
        this.shutdown_price = this.SelectedPlan.plan_shutdown_price
      }
    },
    changePlanPromptModal () {
      if (this.SelectedPlanID !== this.data.plan.id) {
        this.changePlanPrompt = true
      } else {
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: 'پکیج انتخاب شده با پکیج فعلی یکسان می باشد.'
        })
      }
    },
    getFlag () {
      this.$http
        .post('/user/event/get', { service_type: 41 })
        .then((res) => {
          this.flag = res.data.params.flag

          // eslint-disable-next-line no-var
          this.repeat = setTimeout(() => {
            this.getFlag()

          }, 10000)

          if (this.flag === 2) {
            this.setFlag()
            this.getCloudList()
          }
        })
        .catch(() => {})
    },
    setFlag () {
      this.$http
        .post('/user/event/set', { service_type: 40, flag: 1 })
        .then(() => {})
        .catch(() => {})
    },
    changePlan () {
      this.changePlanPrompt = false
      this.$vs.loading()
      this.$http.put(`/cloud/desktop/${this.data.id}/plan`, {
        plan_id : this.SelectedPlanID
      }).then(() => {
        this.getPlans()
        this.$vs.notify({
          color:'green',
          title: 'پلن تغییر یافت '
        })
        this.$vs.loading.close()
      }).catch((err) => {

        this.isLoading = false
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
        this.$vs.loading.close()
      })
    }
  },
  created () {
    this.SelectedPlanID = this.data.plan.id
    this.getPlans()
  }
}
</script>

<style>
tbody tr:nth-child(odd){
  background-color: #090C2B;
  color: #fff;
}
.nav-tabs {
  border: 0;
}
.nav-tabs .nav-link {
    color: #1791da !important;
    margin-right: 10px;
    border: 1px dashed #ffffff78 !important;
    border-radius: 8px !important;
}
.nav-tabs .nav-link:hover {
  color: white !important;
    border-radius: 8px !important;
    border: 1px dashed #1791da !important;
}
[dir] .nav-tabs .nav-link.active, [dir] .nav-tabs .nav-item.show .nav-link {
    background-color: unset !important;

    color: white !important;
    border-radius: 8px !important;
    border: 1px dashed #1791da !important;
}
</style>
