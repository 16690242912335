<template>
  <div class="d-block w-full">
    <div class="flex w-full justify-between">
      <vs-button @click="title.edit = true" class="bg-transparent p-0 m-0 text-xs" v-if="!title.edit">تغییر
        نام</vs-button>
      <h3 class="text-right pb-2 " style="font-family: Segoe UI" v-if="!title.edit">{{ title.name }}</h3>
      <vs-input class="w-11/12 mb-2" size="small" v-if="title.edit" v-model="title.name"></vs-input>

      <vs-button @click="update" class="bg-transparent p-0 m-0 text-xs w-full" v-if="title.edit">اعمال</vs-button>
    </div>
    <vs-divider></vs-divider>

    <div class="font-light rounded p-2 pb-0">

      <div class="text-center flex justify-between gap-8 items-center ">
        <span class="mb-4 block"> وضعیت:
          <span v-if="data.vm_status_title == 'آنلاین'" class="text-h-success "> آنلاین</span>
          <span v-else-if="data.vm_status_title == 'خاموش'" class="text-h-danger "> خاموش</span>
        </span>
        <div class="text-center flex justify-between gap-8 items-center ">

          <div>
            <img width="30" class="m-auto d-block"
            :src="`data:image/png;base64,${data.image.image_logo.attachment_content}`" />
            <p class="d-block" style="color: #9a9a9a">{{ data.image.title }}</p>
          </div>
          <div>
            <img width="30" class="m-auto d-block"
            :src="`data:image/png;base64,${data.country.country_flag_file.attachment_content}`" />
            <p class="d-block" style="color: #9a9a9a">{{ data.country.country_name }}</p>
          </div>
        </div>
      </div>
      <!-- Machine Detail -->

      <div class="flex justify-center gap-2 items-center ">
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer" @click="copyToClipboard(data.vm_sequence_id)">
          {{ data.vm_sequence_id }}
          <span class="text-xs text-white d-block text-center">شماره ماشین</span>
        </div>

      </div>
      <!-- Security Information -->
      <div class="mt-4 flex justify-center gap-2 items-center">
        <div class="bg-h-primary rounded p-2 w-full text-center text-sm " @click="copyToClipboard(data.vdi_panel_url)">
          <a target="_blank" :href="data.vdi_panel_url" class="text-white">{{ data.vdi_panel_url }}</a>
          <span class="text-xs text-white d-block text-center">آدرس ورود به پنل</span>
        </div>
      </div>
      <div class="mt-4 flex justify-center gap-2 items-center">
        <div class="bg-h-primary rounded p-2 w-full text-center text-sm cursor-pointer" @click="copyToClipboard(data.vdi_panel_username), vdiuserblur = !vdiuserblur" :class="{blurtext : vdiuserblur}">
          {{ data.vdi_panel_username }}
          <span class="text-xs text-white d-block text-center">نام کاربری ورود به پنل</span>
        </div>
      </div>

      <div class="mt-4 flex justify-center gap-2 items-center">
        <div class="bg-h-primary rounded p-2 w-full text-center cursor-pointer" @click="copyToClipboard(data.vdi_panel_password), vdipassblur = !vdipassblur" :class="{blurtext : vdipassblur}">
          {{ data.vdi_panel_password }}
          <span class="text-xs text-white d-block text-center">رمز ورود به پنل</span>
        </div>
      </div>

      <div class="mt-4 flex justify-center gap-2 items-center">
        <div class="bg-h-primary rounded p-2 w-1/2 text-center text-xl cursor-pointer" @click="copyToClipboard(data.vm_default_username), userblur = !userblur" :class="{blurtext : userblur}">
          {{ data.vm_default_username }}
          <span class="text-xs text-white d-block text-center">نام کاربر سرور</span>
        </div>
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer" @click="copyToClipboard(data.vm_default_password), passblur = !passblur" :class="{blurtext : passblur}">
          {{ data.vm_default_password }}
          <span class="text-xs text-white d-block text-center">رمز پیشفرض سرور</span>
        </div>
      </div>

      <vs-divider />
      <!-- Machine Actions -->
      <div class="flex items-center mt-3 w-full justify-center" v-if="data.vm_status_title !== 'در حال آماده سازی'">
        <div v-if="data.vm_status_title === 'آنلاین'" :id="'power' + data.id" title="خاموش کردن"
          class="hover:shadow-xl  m-2 p-2  hover:text-white cursor-pointer"
          @click="shutdownPrompt = true, id = data.id">
          <b-tooltip variant="info" :target="'power' + data.id" triggers="hover">
            خاموش کردن
          </b-tooltip>
          <b-icon class="my-2" scale="2" icon="power"></b-icon>
        </div>

        <div v-if="data.vm_status_title === 'خاموش'" id="poweron" title="روشن کردن"
          class="hover:shadow-xl  m-2 p-2  hover:text-white cursor-pointer"
          @click="popupActiveReset = true, id = data.id">
          <b-tooltip variant="info" target="poweron" triggers="hover">
            روشن کردن
          </b-tooltip>
          <b-icon class="my-2" scale="2" icon="power"></b-icon>
        </div>
        <div v-if="data.vm_status_title !== 'خاموش'" :id="'reboot' + data.id" title="خاموش کردن"
          class="hover:shadow-xl  m-2 p-2   hover:text-white cursor-pointer"
          @click="popupActiveReset = true, id = data.id">
          <b-icon class="my-2" scale="2" icon="arrow-clockwise" />
          <b-tooltip variant="info" :target="'reboot' + data.id" triggers="hover">
            ریبوت
          </b-tooltip>
        </div>
        <div :id="'delete' + data.id" @click="setDeletePrompt(data.id), deletePrompt = true, id = data.id"
          class="hover:shadow-xl m-2 p-2  hover:text-white cursor-pointer">
          <b-tooltip variant="info" :target="'delete' + data.id" triggers="hover">
            حذف دسکتاپ
          </b-tooltip>
          <b-icon class="my-2" scale="2" icon="trash"></b-icon>
        </div>
      </div>
    </div>
    <vs-divider />

    <!-- Reboot VM -->
    <vs-popup class="holamundo" title="ری استارت کردن دسکتاپ" :active.sync="popupActiveReset">
      <div class="flex justify-content-center items-center flex-col">
        <div>
          <img width="200" src="../../../../../assets/images/elements/cloud-outline.webp" alt="">
        </div>
        <div>
          آیا از ری استارت کردن دسکتاپ خود مطمئن هستید ؟
        </div>
        <div class="flex justify-content-between mt-4">
          <vs-button color="primary" @click="setRebootPrompt(id)" class="rounded">بله </vs-button>
        </div>
      </div>
    </vs-popup>
    <!-- ShutDown VM -->
    <vs-popup :active.sync="shutdownPrompt" title=" خاموش کردن">
      <div class="p-2">
        <h5>آیا مایل به خاموش کردن دسکتاپ هستید؟</h5>
        <vs-button icon="power" class="rounded float-right" @click="turnOff(id)">خاموش کردن</vs-button>
      </div>
    </vs-popup>
    <!-- delete vira cloud prompt -->
    <div>
      <vs-popup :active.sync="deletePrompt" title="حذف دسکتاپ">
        <div class="con-exemple-prompt">
          <div>
            <p>آیا از حذف دسکتاپ اطمینان دارید. ؟</p>
          </div>
          <vs-button icon="delete" class="float-right rounded w-25" color="danger"
            @click="deleteRecord(id)">حذف</vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'
export default {
  components: {
    RoundSlider
  },
  props: ['data'],
  data () {
    return {
      popupActiveReset: false,
      shutdownPrompt: false,
      deletePrompt: false,
      activePrompt : false,
      selectedID: 0,
      title: { name: this.data.title, edit: false },
      rotation: false,
      vdiuserblur: true,
      vdipassblur: true,
      userblur: true,
      passblur: true
    }
  },
  computed: {
  },
  watch: {
    data () {
      this.title.name = this.data.title
    }

  },
  methods: {
    copyToClipboard (p) {
      navigator.clipboard.writeText(p).then(() => {
        this.$vs.notify({ text: ' کپی شد', color: 'success' })
      })
    },
    deleteRecord (id) {
      this.$http
        .delete(`/cloud/desktop/${id}`)
        .then((res) => {
          this.$vs.notify({
            color: 'warning',
            text: res.data.message,
            title: 'حذف ویرا ابر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف ویرا ابر'
          })
        })
        .finally(() => {
          this.deletePrompt = false
          this.$router.push('/vdi/list')
        })
    },
    setDeletePrompt (d) {
      this.selectedID = d
      this.activePrompt = false
      this.deletePrompt = true
    },
    setRebootPrompt (d) {
      this.reboot(d)
      this.popupActiveReset = false
    },
    turnOff (id) {
      this.$http
        .get(`/cloud/desktop/${id}/shutdown`)
        .then((res) => {
          this.shutdownPrompt = false
          this.data.vm_status_title = 'خاموش'
          this.getCloudList()
          this.$vs.notify({
            color: 'success',
            title: ' ',
            text: res.data.message
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: '  ',
            text: err.response.data.message
          })
        })
    },
    reboot (id) {
      this.rebootPrompt = false
      this.$http
        .get(`/cloud/desktop/${id}/reboot`)
        .then((res) => {
          this.data.vm_status_title = 'آنلاین'
          this.getCloudList()
          this.$vs.notify({
            color: 'success',
            title: ' ',
            text: res.data.message
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: '  ',
            text: err.response.data.message
          })
        })
    },

    update () {
      const emitData = {
        title: this.title.name
      }
      this.$emit('editeCloud', emitData)
      this.title.edit = false

    }
  }
}
</script>

<style scoped>
  .future-card {
    border-bottom: 2px solid #3d2353 !important;
    border-radius: 0;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
</style>
<style>
.rotate {
    -moz-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
}
.rotate.reload {
    -moz-transform:rotate(360deg);
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
}
.blurtext {
  color: transparent;
  text-shadow: 0 0 8px #f1f1f1;
}
</style>
